<script setup lang="ts">
defineProps<{
	title: string;
}>();

const { t } = useT();
const loginGuard = useLoginGuard();
const { open } = useAppModals();

const handleClick = () => {
	loginGuard({
		success: () => {
			navigateTo("/tournaments/");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<MMobNavBanner @click="handleClick">
		<AText :modifiers="['semibold']" class="text-cannes">
			{{ t("mobNavBar.tournament", { title }) }}
		</AText>

		<AAnimationZoomInOut class="logo-wrap" :startScale="1" :finishScale="1.2" :duration="1">
			<NuxtImg
				src="/nuxt-img/tournaments/tournament-nav-mob.png"
				class="logo"
				height="206"
				width="206"
				format="avif"
				loading="lazy"
				alt="tournament-nav-mob"
			/>
		</AAnimationZoomInOut>
	</MMobNavBanner>
</template>

<style lang="scss" scoped>
.logo-wrap {
	position: absolute;
	right: 0;
	bottom: -20px;
}

.logo {
	width: 103px;
	height: auto;
}
</style>
